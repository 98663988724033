import { defineQuery, defineSystem, enterQuery, exitQuery, IWorld, } from 'bitecs';
import {
  AnimationMixer,
  Clock,
  Color,
  Mesh,
  MeshBasicMaterial, MeshLambertMaterial,
  MeshPhongMaterial,
  MeshStandardMaterial,
  Object3D,
  Scene
} from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { MODEL_PATHS, Models, natureMaterial, TEXTURE_PATHS, Textures } from '../../constants';
import { Engine } from '../../engine';
import { Model } from '../components/model';
import { Placeable } from '../components/placeable';

function createModelSystem(engine: Engine, scene: Scene) {
  const entityById = new Map<number, Object3D>();
  const entityQuery = defineQuery([Model]);
  const entityMoveableQuery = defineQuery([Placeable, Model]);

  const clock = new Clock();

  const material = natureMaterial;
  const mixers: AnimationMixer[] = [];

  engine.loaders.loadTexture(TEXTURE_PATHS[Textures.Nature]).then(map => {
    map.flipY = true;
    material.map = map;
    material.needsUpdate = true;
  });

  const entityQueryEnter = enterQuery(entityQuery);
  const entityQueryExit = exitQuery(entityQuery);

  return defineSystem((world: IWorld) => {
    const entitiesEntered = entityQueryEnter(world);
    for (let i = 0; i < entitiesEntered.length; ++i) {
      const id = entitiesEntered[i];
      const object = new Object3D();

      const modelPath = MODEL_PATHS[Math.abs(Model.path[id])];

      if (modelPath) {
        world.engine.loaders.loadModel(modelPath).then((gltf: GLTF) => {
          if (Model.path[id] === Models.Player) {
            gltf.scene.scale.set(0.02,0.02, 0.02);
            // gltf.scene.rotation.y = Math.PI / 2;
            const mixer = new AnimationMixer(gltf.scene);
            gltf.animations.forEach(clip => {
              console.log(clip);
              mixer.clipAction(clip).reset().play();
              mixer.timeScale = 0.7;
              mixers.push(mixer);
            });


            gltf.scene.traverse((object: Object3D) => {
              const mesh = object as Mesh;
              const meshMaterial = mesh.material as MeshBasicMaterial;
              const newMaterial = new MeshBasicMaterial({color: 0x000000});
              mesh.receiveShadow = true;
              mesh.castShadow = true;
              if(meshMaterial) {
               // material.color = new Color(0x000000);
                mesh.material = newMaterial;
              }
            });
          } else {
            gltf.scene.traverse((object: Object3D) => {
              const mesh = object as Mesh;
              mesh.matrixAutoUpdate = false;
              if (mesh.isMesh) {
                mesh.material = material;
                mesh.receiveShadow = true;
                mesh.castShadow = true;
              }
            });
          }
          object.add(gltf.scene);
        });

        scene.add(object);
        entityById.set(id, object);
      }
    }

    const entitiesNeedMoving = entityMoveableQuery(world);
    for (const mixer of mixers) {
      mixer.update(clock.getDelta());
    }
    for (let i = 0; i < entitiesNeedMoving.length; ++i) {
      const id = entitiesNeedMoving[i];
      const entity = entityById.get(id);
      if (entity) {
        entity.position.set(Placeable.x[id], Placeable.y[id], Placeable.z[id]);
        // entity.rotation.set(Placeable.rotationX[id], Placeable.rotationY[id], Placeable.rotationZ[id]);
      }
    }

    const entitiesExited = entityQueryExit(world);
    for (let i = 0; i < entitiesExited.length; ++i) {
      const id = entitiesExited[i];
      const entity = entityById.get(id);

      if (entity) {
        scene.remove(entity);
        // dispose(entity);
        entityById.delete(id);
      }
    }

    return world;
  });
}

export { createModelSystem };
