import { defineQuery, defineSystem, IWorld, } from 'bitecs';
import { random } from 'lodash-es';
import { MAX_DISTANCE_FROM_PLAYER, MIN_DISTANCE_FROM_PLAYER, REPEAT_SIZE, TOP_BOTTOM_RATIO } from '../../constants';
import { Model } from '../components/model';
import { Placeable } from '../components/placeable';

function createMeshRepeatSystem() {
  const entityMoveableQuery = defineQuery([Placeable, Model]);

  return defineSystem((world: IWorld) => {
    const entitiesNeedMoving = entityMoveableQuery(world);
    for (let i = 0; i < entitiesNeedMoving.length; ++i) {
      const id = entitiesNeedMoving[i];

      const z = random(MIN_DISTANCE_FROM_PLAYER, MAX_DISTANCE_FROM_PLAYER, true);
      const topOrBottom = Math.random() > TOP_BOTTOM_RATIO ? -1 : 1;
      const offset = random(REPEAT_SIZE / 4);

      if (Placeable.x[id] < -REPEAT_SIZE) {
        // Placeable.x[id] = 100;
        Placeable.x[id] = REPEAT_SIZE - offset;
        Placeable.z[id] = z * topOrBottom;
      } else if (Placeable.x[id] > REPEAT_SIZE) {
        // Placeable.x[id] = -100;
        Placeable.x[id] = -REPEAT_SIZE + offset;
        Placeable.z[id] = z * topOrBottom;
      }
    }

    return world;
  });
}

export { createMeshRepeatSystem };
