import { defineComponent, Types } from 'bitecs';

const Velocity = defineComponent({
  x: Types.f32,
  y: Types.f32,
  z: Types.f32,
  max: Types.f32,
  min: Types.f32,
  dampingFactor: Types.f32,
});

export { Velocity };
