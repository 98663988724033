class Key {

  readonly value: string;
  private _down: boolean = false;
  private _timeSinceDown: number = 0;

  constructor(value: string) {
    this.value = value;
  }

  get timeSinceDown(): number {
    return this._timeSinceDown;
  }

  set timeSinceDown(value: number) {
    this._timeSinceDown = value;
  }

  get down(): boolean {
    this._timeSinceDown = performance.now();
    return this._down;
  }

  set down(value: boolean) {
    this._down = value;
  }

  public reset() {
    this.down = false;
  }
}

export { Key };
