import { defineQuery, defineSystem, IWorld } from 'bitecs';
import { PerspectiveCamera } from 'three';
import { Camera } from '../components/camera';
import { Placeable } from '../components/placeable';
import { Protagonist } from '../components/protagonist';

function createCameraSystem(camera: PerspectiveCamera) {
  const entityQuery = defineQuery([Placeable, Protagonist]);

  return defineSystem((world: IWorld) => {
    const entities = entityQuery(world);

    for (let i = 0; i < entities.length; ++i) {
      const id = entities[i];
      camera.position.set(
        Placeable.x[id] + Camera.offsetX[id],
        Placeable.y[id] + Camera.offsetY[id],
        Placeable.z[id] + Camera.offsetZ[id],
      );

      camera.lookAt(
        Placeable.x[id],
        Placeable.y[id] + 2,
        Placeable.z[id],
      );
    }

    return world;
  });
}

export { createCameraSystem };
