import {Event, TextureEncoding, Vector2} from 'three';
import {Boundaries} from '../common/utilities/math/boundaries';

export interface Cache<T> {
  [key: string]: T
}

export interface TextureManifestItem {
  ktx2?: string;
  image: string;
  encoding?: TextureEncoding;
}

export interface TextureManifest {
  [key: string]: TextureManifestItem
}

export type LoadManifest = {
  models?: {path: string}[],
  textures?: TextureManifestItem[]
};

export enum ENGINE_EVENTS {
  pointerDown = 'pointerdown',
  pointerMove = 'pointermove',
  pointerUp = 'pointerup',
  resize = 'resize',
  visibilitychange = 'visibilitychange',
}

export interface EnginePointerEvent extends Event {
  pointer: Vector2;
}

export interface EngineResizeEvent extends Event {
  boundaries: Boundaries;
}

/** Path to the BASIS transcoder. */
export const BASIS_PATH = './assets/transcoders/basis/';
/** Path to DRACO transcoder. */
export const DRACO_PATH = './assets/transcoders/draco/';
/** Path to a KTX2 texture with the purpose of confirming KTX2 support. */
export const PATH_TO_KTX2_TEXTURE =
    '/assets/textures/support/support.ktx2';


export const DEBUG_LOG_TEXTURE_TRANSFER_TIME = false;
