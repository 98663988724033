/**
 * @fileoverview
 * Common shapes that can be re-used across actors for optimal GPU usage.
 */
import { PlaneGeometry, SphereGeometry } from 'three';

export const sphere = new SphereGeometry(0.5, 48, 48);

export const plane = new PlaneGeometry(1, 1);
plane.rotateX(-Math.PI / 2);

export const planeWall = new PlaneGeometry(1, 1);
