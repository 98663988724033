import { Key } from './key';

class Keyboard {
  public readonly keys: Map<string, Key> = new Map();
  public allKeysPressed:Key[] = [];

  constructor() {
    window.addEventListener('keydown', this.onKeyDown);
    window.addEventListener('keyup', this.onKeyUp);
    window.addEventListener('blur', this.onBlur);
  }

  get lastKeyPressed() {
    return this.allKeysPressed[this.allKeysPressed.length - 1];
  }


  private readonly onKeyDown = (event: KeyboardEvent) => {
    const value = event.key;

    if (this.keys.has(value)) {
      const key = this.keys.get(value);

      if (key && !key.down) {
        key.down = true;
        this.allKeysPressed.push(key);
      }
    }
  };

  private readonly onKeyUp = (event: KeyboardEvent) => {
    const value = event.key;

    if (this.keys.has(value)) {
      const key = this.keys.get(value);

      if (key?.down) {
        key.down = false;
        const index = this.allKeysPressed.indexOf(key);
        if (index > -1) this.allKeysPressed.splice(index, 1);
      }
    }
  };

  private readonly onBlur = () => {
    this.keys.forEach(key => key.reset());
    this.allKeysPressed = [];
  };

  public addKeys(...keys: Key[]): void {
    for (const key of keys) this.keys.set(key.value, key);
  }

  public clearKeys(): void {
    this.keys.clear();
    this.allKeysPressed = [];
  }
}

export { Keyboard };
