/**
 * @fileoverview
 * One `Engine` instance is is passed down every to every component of the app.
 * It contains critical information and resources that need to be accessible
 *    throughout the entire WebGL hierarchy.
 */

import { Event, WebGLRenderer } from 'three';
import { createRenderer } from '../common/renderer';

import { Boundaries } from '../common/utilities/math/boundaries';

import { ENGINE_EVENTS, EngineResizeEvent } from './constants';
import { EngineEvents } from './events';
import { Loaders } from './loaders';


class Engine {
  // Store the containing element in case Shadow DOM is being used.
  public readonly domScope: HTMLElement;
  // Renderer tied to our context.
  public readonly renderer: WebGLRenderer;
  // Viewport boundaries and scaling.
  public readonly boundaries: Boundaries;
  // Collection of loaders.
  public readonly loaders: Loaders;
  // Global events.
  public readonly events: EngineEvents;

  constructor(domContainer: HTMLElement, canvas: HTMLCanvasElement, boundaries: Boundaries) {
    this.domScope = domContainer;
    this.renderer = createRenderer({ canvas });
    this.boundaries = boundaries;
    this.loaders = new Loaders(this.renderer);

    this.events = new EngineEvents(this.boundaries);
    this.events.addEventListener(ENGINE_EVENTS.resize, this.resize);
  }

  async setup() {
    await this.loaders.setup();
  }

  public dispose() {
    this.loaders.dispose();
    this.renderer.dispose();
  }

  private readonly resize = (e: Event) => {
    const event = e as EngineResizeEvent;
    // Then use the boundaries properties to set the renderer's size.
    this.renderer.setSize(event.boundaries.width, event.boundaries.height);
    this.renderer.setPixelRatio(event.boundaries.pixelRatio);
  };
}

export { Engine };
