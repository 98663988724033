import {EventDispatcher} from 'three';

import {Boundaries} from '../common/utilities/math/boundaries';
import {normalisePointer} from '../common/utilities/math/normalise-pointer';

import {ENGINE_EVENTS} from './constants';

/**
 * Engine events separated to keep the main engine small.
 */
class EngineEvents extends EventDispatcher {
  private readonly boundaries: Boundaries;

  constructor(boundaries: Boundaries) {
    super();
    this.boundaries = boundaries;
  }

  public pointerDown = (x: number, y: number) => {
    const {width, height} = this.boundaries;
    const pointer = normalisePointer(width, height, x, y);
    this.dispatchEvent({type: ENGINE_EVENTS.pointerDown, pointer});
  };

  public pointerMove = (x: number, y: number) => {
    const {width, height} = this.boundaries;
    const pointer = normalisePointer(width, height, x, y);
    this.dispatchEvent({type: ENGINE_EVENTS.pointerMove, pointer});
  };

  public pointerUp = () => this.dispatchEvent({type: ENGINE_EVENTS.pointerUp});

  public visibilityChange = (visible: boolean) => {
    console.warn({visible});
    this.dispatchEvent({type: ENGINE_EVENTS.visibilitychange, visible});
  };

  public resize = (width: number, height: number, devicePixelRatio: number) => {
    // Set the size of the boundaries.
    this.boundaries.resize(width, height, devicePixelRatio);
    this.dispatchEvent(
        {type: ENGINE_EVENTS.resize, boundaries: this.boundaries});
  };
}

export {EngineEvents};
