import { Key } from './key';

export class WKey extends Key {
  static readonly value = 'w';
  constructor() {
    super(WKey.value);
  }
}

export class AKey extends Key {
  static readonly value = 'a';
  constructor() {
    super(AKey.value);
  }
}

export class SKey extends Key {
  static readonly value = 's';
  constructor() {
    super(SKey.value);
  }
}

export class DKey extends Key {
  static readonly value = 'd';
  constructor() {
    super(DKey.value);
  }
}

export class ArrowUpKey extends Key {
  static readonly value = 'ArrowUp';
  constructor() {
    super(ArrowUpKey.value);
  }
}

export class ArrowLeftKey extends Key {
  static readonly value = 'ArrowLeft';
  constructor() {
    super(ArrowLeftKey.value);
  }
}

export class ArrowDownKey extends Key {
  static readonly value = 'ArrowDown';
  constructor() {
    super(ArrowDownKey.value);
  }
}

export class ArrowRightKey extends Key {
  static readonly value = 'ArrowRight';
  constructor() {
    super(ArrowRightKey.value);
  }
}

export class SpaceKey extends Key {
  static readonly value = ' ';
  constructor() {
    super(SpaceKey.value);
  }
}
