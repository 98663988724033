import { defineComponent, Types } from 'bitecs';

const Placeable = defineComponent({
  x: Types.f32,
  y: Types.f32,
  z: Types.f32,
  rotationX: Types.f32,
  rotationY: Types.f32,
  rotationZ: Types.f32,
});

export { Placeable };
