import { MeshBasicMaterial, MeshLambertMaterial } from 'three';

export enum Direction {
  None,
  Left,
  Right,
  Up,
  Down
}

export enum PointerButton {
  Up,
  Down
}


export const CYCLE_TIMER = 300000; // 10minutes.

const MODIFIER = 0.5;

export const CAMERA_ANGLES = [
  [0, 0, 400 * MODIFIER],
  [0, -400 * MODIFIER, 0],
  [13 * MODIFIER, 41 * MODIFIER, 56 * MODIFIER],
  [1 * MODIFIER, 16 * MODIFIER, 23.7],
  [-60 * MODIFIER, 56 * MODIFIER, 32 * MODIFIER],
];

export const natureMaterial = new MeshLambertMaterial();

export const MIN_DISTANCE_FROM_PLAYER = 2;
export const MAX_DISTANCE_FROM_PLAYER = 50 * MODIFIER;
export const FOREST_ELEMENT_AMOUNT = 400 * MODIFIER;
export const TOP_BOTTOM_RATIO = 0.5;
export const REPEAT_SIZE = 150 * MODIFIER;
export const CAMERA_FOV = 30;


export const MODEL_PATHS = [
  '',
  './assets/models/girl2.glb',
  './assets/models/environment/bush-a-01.glb',
  './assets/models/environment/bush-a-02.glb',
  './assets/models/environment/bush-a-03.glb',
  './assets/models/environment/bush-b-01.glb',
  './assets/models/environment/bush-b-02.glb',
  './assets/models/environment/bush-b-03.glb',
  './assets/models/environment/bush-c-01.glb',
  './assets/models/environment/bush-c-02.glb',
  './assets/models/environment/bush-c-03.glb',
  './assets/models/environment/bush-d-01.glb',
  './assets/models/environment/bush-d-02.glb',
  './assets/models/environment/bush-d-03.glb',
  './assets/models/environment/bush-e-01.glb',
  './assets/models/environment/bush-e-02.glb',
  './assets/models/environment/bush-e-03.glb',
  './assets/models/environment/bush-f-01.glb',
  './assets/models/environment/bush-f-02.glb',
  './assets/models/environment/bush-f-03.glb',
  './assets/models/environment/bush-flower-a-01.glb',
  './assets/models/environment/bush-flower-a-02.glb',
  './assets/models/environment/bush-flower-a-03.glb',
  './assets/models/environment/flora-a-01.glb',
  './assets/models/environment/flora-a-02.glb',
  './assets/models/environment/flora-a-03.glb',
  './assets/models/environment/flora-b-01.glb',
  './assets/models/environment/flora-b-02.glb',
  './assets/models/environment/flora-b-03.glb',
  './assets/models/environment/flower-b-01.glb',
  './assets/models/environment/flower-b-02.glb',
  './assets/models/environment/flower-b-03.glb',
  './assets/models/environment/fungus-a-01.glb',
  './assets/models/environment/fungus-a-02.glb',
  './assets/models/environment/fungus-a-03.glb',
  './assets/models/environment/grass-a-01.glb',
  './assets/models/environment/grass-a-02.glb',
  './assets/models/environment/grass-a-03.glb',
  './assets/models/environment/grass-a-04.glb',
  './assets/models/environment/grass-a-05.glb',
  './assets/models/environment/grass-a-06.glb',
  './assets/models/environment/grass-b-01.glb',
  './assets/models/environment/grass-b-02.glb',
  './assets/models/environment/grass-b-03.glb',
  './assets/models/environment/grass-b-04.glb',
  './assets/models/environment/grass-b-05.glb',
  './assets/models/environment/grass-b-06.glb',
  './assets/models/environment/herb-a-01.glb',
  './assets/models/environment/herb-a-02.glb',
  './assets/models/environment/herb-a-03.glb',
  './assets/models/environment/herb-b-01.glb',
  './assets/models/environment/herb-b-02.glb',
  './assets/models/environment/herb-b-03.glb',
  './assets/models/environment/herb-c-01.glb',
  './assets/models/environment/herb-c-02.glb',
  './assets/models/environment/herb-c-03.glb',
  './assets/models/environment/plant-a-001.glb',
  './assets/models/environment/plant-a-002.glb',
  './assets/models/environment/plant-a-003.glb',
  './assets/models/environment/plant-b-001.glb',
  './assets/models/environment/plant-b-002.glb',
  './assets/models/environment/plant-b-003.glb',
  './assets/models/environment/plant-c-001.glb',
  './assets/models/environment/plant-c-002.glb',
  './assets/models/environment/plant-c-003.glb',
  './assets/models/environment/rock-a-001.glb',
  './assets/models/environment/rock-a-002.glb',
  './assets/models/environment/rock-a-003.glb',
  './assets/models/environment/rock-a-004.glb',
  './assets/models/environment/rock-a-005.glb',
  './assets/models/environment/rock-a-006.glb',
  './assets/models/environment/rock-b-001.glb',
  './assets/models/environment/rock-b-002.glb',
  './assets/models/environment/rock-b-003.glb',
  './assets/models/environment/rock-b-004.glb',
  './assets/models/environment/rock-b-005.glb',
  './assets/models/environment/rock-b-006.glb',
  './assets/models/environment/rock-c-001.glb',
  './assets/models/environment/rock-c-002.glb',
  './assets/models/environment/rock-c-003.glb',
  './assets/models/environment/rock-c-004.glb',
  './assets/models/environment/rock-c-005.glb',
  './assets/models/environment/rock-c-006.glb',
  './assets/models/environment/shrub-leaves-001.glb',
  './assets/models/environment/shrub-leaves-002.glb',
  './assets/models/environment/shrub-leaves-003.glb',
  './assets/models/environment/shrub-naked-a-001.glb',
  './assets/models/environment/shrub-naked-a-002.glb',
  './assets/models/environment/shrub-naked-a-003.glb',
  './assets/models/environment/shrub-naked-b-001.glb',
  './assets/models/environment/shrub-naked-b-002.glb',
  './assets/models/environment/shrub-naked-b-003.glb',
  './assets/models/environment/tree-conifer-01.glb',
  './assets/models/environment/tree-conifer-02.glb',
  './assets/models/environment/tree-conifer-03.glb',
  './assets/models/environment/tree-conifer-04.glb',
  './assets/models/environment/tree-conifer-05.glb',
  './assets/models/environment/tree-conifer-06.glb',
  './assets/models/environment/tree-d-001.glb',
  './assets/models/environment/tree-d-002.glb',
  './assets/models/environment/tree-d-003.glb',
  './assets/models/environment/tree-e-001.glb',
  './assets/models/environment/tree-e-002.glb',
  './assets/models/environment/tree-e-003.glb',
  './assets/models/environment/tree-f-001.glb',
  './assets/models/environment/tree-f-002.glb',
  './assets/models/environment/tree-f-003.glb',
  './assets/models/environment/tree-giant-001.glb',
  './assets/models/environment/tree-giant-002.glb',
  './assets/models/environment/tree-giant-003.glb',
  './assets/models/environment/tree-log-001.glb',
  './assets/models/environment/tree-log-002.glb',
  './assets/models/environment/tree-log-003.glb',
  './assets/models/environment/tree-naked-a-001.glb',
  './assets/models/environment/tree-naked-a-002.glb',
  './assets/models/environment/tree-naked-a-003.glb',
  './assets/models/environment/tree-naked-b-001.glb',
  './assets/models/environment/tree-naked-b-002.glb',
  './assets/models/environment/tree-naked-b-003.glb',
  './assets/models/environment/tree-naked-c-001.glb',
  './assets/models/environment/tree-naked-c-002.glb',
  './assets/models/environment/tree-naked-c-003.glb',
  './assets/models/environment/tree-naked-d-001.glb',
  './assets/models/environment/tree-naked-d-002.glb',
  './assets/models/environment/tree-naked-d-003.glb',
  './assets/models/environment/tree-pine-001.glb',
  './assets/models/environment/tree-pine-002.glb',
  './assets/models/environment/tree-pine-003.glb',
  './assets/models/environment/tree-pine-004.glb',
  './assets/models/environment/tree-pine-005.glb',
  './assets/models/environment/tree-pine-006.glb',
  './assets/models/environment/tree-root-001.glb',
  './assets/models/environment/tree-root-002.glb',
  './assets/models/environment/tree-root-003.glb',
  './assets/models/environment/tree-simple-001.glb',
  './assets/models/environment/tree-simple-002.glb',
  './assets/models/environment/tree-simple-003.glb',
  './assets/models/environment/tree-stump-a-001.glb',
  './assets/models/environment/tree-stump-a-002.glb',
  './assets/models/environment/tree-stump-a-003.glb',
  './assets/models/environment/tree-stump-b-001.glb',
  './assets/models/environment/tree-stump-b-002.glb',
  './assets/models/environment/tree-stump-b-003.glb',
  './assets/models/environment/tree-top-a-001.glb',
  './assets/models/environment/tree-top-a-002.glb',
  './assets/models/environment/tree-top-a-003.glb',
  './assets/models/environment/tree-trunkdead-01.glb',
  './assets/models/environment/tree-trunkdead-02.glb',
  './assets/models/environment/tree-trunkdead-03.glb',
];

export const TEXTURE_PATHS = [
  '',
  './assets/textures/nature-palette.png',
];

export enum Models {
  Undefined,
  Player,
  BushA01,
  BushA02,
  BushA03,
  BushB01,
  BushB02,
  BushB03,
  BushC01,
  BushC02,
  BushC03,
  BushD01,
  BushD02,
  BushD03,
  BushE01,
  BushE02,
  BushE03,
  BushF01,
  BushF02,
  BushF03,
  BushFlowerA01,
  BushFlowerA02,
  BushFlowerA03,
  FloraA01,
  FloraA02,
  FloraA03,
  FloraB01,
  FloraB02,
  FloraB03,
  FlowerB01,
  FlowerB02,
  FlowerB03,
  FungusA01,
  FungusA02,
  FungusA03,
  GrassA01,
  GrassA02,
  GrassA03,
  GrassA04,
  GrassA05,
  GrassA06,
  GrassB01,
  GrassB02,
  GrassB03,
  GrassB04,
  GrassB05,
  GrassB06,
  HerbA01,
  HerbA02,
  HerbA03,
  HerbB01,
  HerbB02,
  HerbB03,
  HerbC01,
  HerbC02,
  TerbC03,
  PlantA001,
  PlantA002,
  PlantA003,
  PlantB001,
  PlantB002,
  PlantB003,
  PlantC001,
  PlantC002,
  PlantC003,
  RockA001,
  RockA002,
  RockA003,
  RockA004,
  RockA005,
  RockA006,
  RockB001,
  RockB002,
  RockB003,
  RockB004,
  RockB005,
  RockB006,
  RockC001,
  RockC002,
  RockC003,
  RockC004,
  RockC005,
  RockC006,
  ShrubLeaves001,
  ShrubLeaves002,
  ShrubLeaves003,
  ShrubNakedA001,
  ShrubNakedA002,
  ShrubNakedA003,
  ShrubNakedB001,
  ShrubNakedB002,
  ShrubNakedB003,
  TreeConifer01,
  TreeConifer02,
  TreeConifer03,
  TreeConifer04,
  TreeConifer05,
  TreeConifer06,
  TreeD001,
  TreeD002,
  TreeD003,
  TreeE001,
  TreeE002,
  TreeE003,
  TreeF001,
  TreeF002,
  TreeF003,
  TreeGiant001,
  TreeGiant002,
  TreeGiant003,
  TreeLog001,
  TreeLog002,
  TreeLog003,
  TreeNakedA001,
  TreeNakedA002,
  TreeNakedA003,
  TreeNakedB001,
  TreeNakedB002,
  TreeNakedB003,
  TreeNakedC001,
  TreeNakedC002,
  TreeNakedC003,
  TreeNakedD001,
  TreeNakedD002,
  TreeNakedD003,
  TreePine001,
  TreePine002,
  TreePine003,
  TreePine004,
  TreePine005,
  TreePine006,
  TreeRoot001,
  TreeRoot002,
  TreeRoot003,
  TreeRimple001,
  TreeRimple002,
  TreeRimple003,
  TreeRtumpA001,
  TreeRtumpA002,
  TreeRtumpA003,
  TreeRtumpB001,
  TreeRtumpB002,
  TreeRtumpB003,
  TreeTopA001,
  TreeTopA002,
  TreeTopA003,
  TreeTrunkdead01,
  TreeTrunkdead02,
  TreeTrunkdead03,
}

export enum Textures {
  Undefined,
  Nature,
  NatureEnv
}

