import { AKey, ArrowDownKey, ArrowLeftKey, ArrowRightKey, ArrowUpKey, DKey, SKey, SpaceKey, WKey } from './keys';


export function commonKeys() {
  return [
    new WKey(),
    new AKey(),
    new SKey(),
    new DKey(),
    new ArrowUpKey(),
    new ArrowLeftKey(),
    new ArrowDownKey(),
    new ArrowRightKey(),
    new SpaceKey(),
  ];
}
