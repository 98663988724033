import {Vector2} from 'three';

/**
 * Turn the pointer into a vector2 ranging from -1 to 1.
 *
 * @example
 * Top-left: [-1, -1]
 * Top-right: [1, -1]
 * Bottom-right: [1, -1]
 * Bottom-left: [1, 1]
 * Center: [0, 0]
 */
function normalisePointer(
    canvasWidth: number, canvasHeight: number, x: number, y: number): Vector2 {
  // X going from -1 (left) to 1 (right).
  const xNormalised = (x / canvasWidth) * 2 - 1;
  // Y going from -1 (top) to 1 (bottom).
  const yNormalised = -(y / canvasHeight) * 2 + 1;
  return new Vector2(xNormalised, yNormalised);
}

export {normalisePointer};
