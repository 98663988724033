import { Engine } from './engine';
import { WorldSecond } from './worlds/world-second';

class Game {
  readonly engine: Engine;
  readonly world: WorldSecond;

  constructor(engine: Engine) {
    this.engine = engine;
    this.world = new WorldSecond(this.engine);
  }

  public readonly start = async () => {
    this.engine.renderer.setAnimationLoop(this.render);
    // if ('wakeLock' in navigator) {
    //   console.log('a');
    //   // @ts-ignore
    //   await navigator.wakeLock.request('screen');
    //   console.log('b');
    // }
  };
  public readonly stop = () => this.engine.renderer.setAnimationLoop(null);

  public resize(width: number, height: number, pixelRatio: number) {
    this.engine.events.resize(width, height, pixelRatio);
    this.world.resize(this.engine.boundaries);
  }

  private readonly render = () => {
    this.world.update();
    this.world.postProcessing.render();
    // this.engine.renderer.render(this.world.scene, this.world.camera);
  };
}

export { Game };
