import { defineQuery, defineSystem, IWorld } from 'bitecs';
import { Keyboard } from '../../common/utilities/keyboard';
import {
  AKey,
  ArrowDownKey,
  ArrowLeftKey,
  ArrowRightKey,
  ArrowUpKey,
  DKey,
  SKey,
  WKey
} from '../../common/utilities/keyboard/keys';
import { Placeable } from '../components/placeable';
import { UserControlled } from '../components/user-controlled';
import { Velocity } from '../components/velocity';

function createControlsSystem(keyboard: Keyboard) {
  const entityQuery = defineQuery([Placeable, Velocity, UserControlled]);

  return defineSystem((world: IWorld) => {
    const lastKeyPressed = keyboard.lastKeyPressed;

    const entities = entityQuery(world);
    for (let i = 0; i < entities.length; ++i) {
      const id = entities[i];

      const damping = Velocity.dampingFactor[id];

      if (lastKeyPressed) {
        switch (lastKeyPressed.value) {
          case ArrowUpKey.value:
          case WKey.value:
            if (!damping) Velocity.x[id] = Velocity.min[id];
            Velocity.z[id] = -Velocity.max[id];
            break;
          case ArrowLeftKey.value:
          case AKey.value:
            Velocity.x[id] = -Velocity.max[id];
            if (!damping) Velocity.z[id] = Velocity.min[id];
            break;
          case ArrowDownKey.value:
          case SKey.value:
            if (!damping) Velocity.x[id] = Velocity.min[id];
            Velocity.z[id] = Velocity.max[id];
            break;
          case ArrowRightKey.value:
          case DKey.value:
            Velocity.x[id] = Velocity.max[id];
            if (!damping) Velocity.z[id] = Velocity.min[id];
            break;
        }
      } else if (!damping) {
        Velocity.x[id] = Velocity.min[id];
        Velocity.z[id] = Velocity.min[id];
      }

      if (damping) {
        // Damping.
        Velocity.x[id] *= (1 - Velocity.dampingFactor[id]);
        Velocity.z[id] *= (1 - Velocity.dampingFactor[id]);
      }
    }

    return world;
  });
}

export { createControlsSystem };
