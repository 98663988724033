/** Read a file as an ArrayBuffer. */
async function parseFile(file: File): Promise<ArrayBuffer> {
  const reader = new FileReader();

  const promise: Promise<ArrayBuffer> = new Promise(resolve => {
    reader.addEventListener('load', (event: ProgressEvent<FileReader>) => {
      const contents = event.target!.result as ArrayBuffer|undefined;
      if (contents) resolve(contents);
    });
  });

  reader.readAsArrayBuffer(file);

  return promise;
}

export {parseFile};
