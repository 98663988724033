import { addComponent, addEntity, createWorld, pipe } from 'bitecs';
import { random, remove, sample } from 'lodash-es';
import {
  AmbientLight,
  Color,
  DirectionalLight,
  Fog,
  HemisphereLight,
  Mesh,
  MeshLambertMaterial,
  PerspectiveCamera,
  Scene
} from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { plane } from '../common/common-geometries';
import { Keyboard } from '../common/utilities/keyboard';
import { commonKeys } from '../common/utilities/keyboard/common-keys';
import { Boundaries } from '../common/utilities/math/boundaries';
import {
  CAMERA_ANGLES,
  CAMERA_FOV, CYCLE_TIMER,
  FOREST_ELEMENT_AMOUNT,
  MAX_DISTANCE_FROM_PLAYER,
  MIN_DISTANCE_FROM_PLAYER,
  Models,
  REPEAT_SIZE,
  Textures,
  TOP_BOTTOM_RATIO
} from '../constants';
import { Camera } from '../ecs/components/camera';
import { Model } from '../ecs/components/model';
import { Placeable } from '../ecs/components/placeable';
import { Protagonist } from '../ecs/components/protagonist';
import { Velocity } from '../ecs/components/velocity';
import { createCameraSystem } from '../ecs/systems/camera';
import { createControlsSystem } from '../ecs/systems/controls';
import { createMeshRepeatSystem } from '../ecs/systems/mesh-repeat';
import { createModelSystem } from '../ecs/systems/model';
import { createMovementSystem } from '../ecs/systems/movement';
import { RenderPipeline, World } from '../ecs/typings';
import { Engine } from '../engine';
import { PostProcessing } from '../engine/post-processing';

let angles = [...CAMERA_ANGLES];

function randomCameraAngle() {
  const angle = sample(angles) as number[];

  const index = angles.indexOf(angle);
  if (index > -1) angles.splice(index, 1);

  if (!angles.length) angles = [...CAMERA_ANGLES];
  return angle;
}

const randomInt = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

class WorldSecond {
  readonly world: World;
  readonly scene = new Scene();
  readonly camera = new PerspectiveCamera();
  readonly renderPipeline: RenderPipeline;
  readonly keyboard: Keyboard = new Keyboard();
  readonly postProcessing: PostProcessing;

  // readonly environment: Environment;

  constructor(engine: Engine) {
    const keys = commonKeys();
    for (const key of keys) this.keyboard.addKeys(key);

    this.camera.fov = CAMERA_FOV;
    this.postProcessing = new PostProcessing(engine, this.scene, this.camera);


    // const environment = new Environment(engine, this.scene);

    const ambientLight = new AmbientLight(0xffffff, 3);
    this.scene.add(ambientLight);
    // const hemisphereLight = new HemisphereLight(0xf2f3f4, 0xf2f3ee, 1);
    // this.scene.add(hemisphereLight);
    const light = new DirectionalLight(0xffffff, 3);
    //Set up shadow properties for the light
    light.shadow.mapSize.width = 1024; // default
    light.shadow.mapSize.height = 1024; // default
    light.shadow.camera.near = 0.1; // default
    light.shadow.camera.far = 1280; // default
    const size = 128;
    light.shadow.camera.top = -size; // default
    light.shadow.camera.right = size; // default
    light.shadow.camera.left = -size; // default
    light.shadow.camera.bottom = size; // default
    light.castShadow = true;
    light.position.set(80, 300, 100);
    this.scene.add(light);
    //
    const floor = plane;
    floor.scale(320, 320, 320);
    const f = new Mesh(floor, new MeshLambertMaterial({ color: 0x8bc34a }));
    f.receiveShadow = true;
    this.scene.add(f);
    //
    this.scene.background = new Color(0x8bc34a);
    // this.scene.fog = new Fog(this.scene.background, 10, 55);


    this.world = createWorld({ engine }) as World;


    const player = addEntity(this.world);
    addComponent(this.world, Placeable, player);
    addComponent(this.world, Protagonist, player);
    addComponent(this.world, Model, player);
    addComponent(this.world, Camera, player);
    const [x, y, z] = randomCameraAngle() as number[];
    Camera.offsetX[player] = x;
    Camera.offsetY[player] = y;
    Camera.offsetZ[player] = z;
    window.setInterval(() => {
      const [x, y, z] = randomCameraAngle() as number[];
      Camera.offsetX[player] = x;
      Camera.offsetY[player] = y;
      Camera.offsetZ[player] = z;
    }, CYCLE_TIMER);

    window.addEventListener('pointerdown', () => {
      const [x, y, z] = randomCameraAngle() as number[];
      Camera.offsetX[player] = x;
      Camera.offsetY[player] = y;
      Camera.offsetZ[player] = z;
    });



    Model.path[player] = Models.Player;

    const environmentElements: number[] = [];

    while (environmentElements.length < FOREST_ELEMENT_AMOUNT) {
      const environmentElement = addEntity(this.world);
      addComponent(this.world, Placeable, environmentElement);
      addComponent(this.world, Velocity, environmentElement);
      addComponent(this.world, Model, environmentElement);

      Model.path[environmentElement] = randomInt(2, 148);
      Model.texture[environmentElement] = Textures.Nature;
      Velocity.x[environmentElement] = -0.15;
      Velocity.max[environmentElement] = 0.2;

      const offsetX = random(-REPEAT_SIZE, REPEAT_SIZE, true);
      Placeable.x[environmentElement] = offsetX;
      const z = random(MIN_DISTANCE_FROM_PLAYER, MAX_DISTANCE_FROM_PLAYER, true);
      const topOrBottom = Math.random() > TOP_BOTTOM_RATIO ? -1 : 1;
      Placeable.z[environmentElement] = z * topOrBottom;
      Placeable.rotationY[environmentElement] = Math.random() * (Math.PI * 2);
      Placeable.rotationY[environmentElement] = Math.random() * (Math.PI * 2);

      environmentElements.push(environmentElement);
    }

    const modelSystem = createModelSystem(engine, this.scene);
    const controlSystem = createControlsSystem(this.keyboard);
    const cameraSystem = createCameraSystem(this.camera);
    // this.camera.position.z = 500;
    // const controls = new OrbitControls(this.camera, engine.renderer.domElement);
    const movementSystem = createMovementSystem();
    const meshRepeatSystem = createMeshRepeatSystem();

    this.renderPipeline = pipe(
      modelSystem,
      controlSystem,
      cameraSystem,
      meshRepeatSystem,
      movementSystem,
    );
  }

  public resize(boundaries: Boundaries) {
    this.camera.aspect = boundaries.aspect;
    this.camera.updateProjectionMatrix();

    this.postProcessing.setSize(boundaries.width, boundaries.height);
  }

  public update() {
    this.renderPipeline(this.world);
  }
}

export { WorldSecond };
