import {Vector2} from 'three';

const PIXEL_RATIO_MAXIMUM = 1.5;

/**
 * Source of truth regarding canvas size and pixel density.
 * The pixel density is capped at 1.5 for performance reasons.
 */
class Boundaries {
  private _aspect = 0;
  private _pixelRatio = 0;
  public readonly vec2 = new Vector2();
  public maxPixelRatio = PIXEL_RATIO_MAXIMUM;

  constructor(width: number, height: number, pixelRatio: number) {
    this.resize(width, height, pixelRatio);
  }

  get width(): number {
    return this.vec2.width;
  }

  get height(): number {
    return this.vec2.height;
  }

  get aspect(): number {
    return this._aspect;
  }

  get pixelRatio(): number {
    return this._pixelRatio;
  }

  public resize(width: number, height: number, pixelRatio?: number) {
    if (pixelRatio) {
      this._pixelRatio = Math.min(pixelRatio, this.maxPixelRatio);
    }
    this.vec2.width = width;
    this.vec2.height = height;
    this._aspect = width / height;
  }
}

export {Boundaries};
