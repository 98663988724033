import { sample } from 'lodash-es';
import { Camera, PCFSoftShadowMap, Scene, sRGBEncoding } from 'three';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { HalftonePass } from 'three/examples/jsm/postprocessing/HalftonePass';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { SSRPass } from 'three/examples/jsm/postprocessing/SSRPass';
import { LuminosityShader } from 'three/examples/jsm/shaders/LuminosityShader';
import { SobelOperatorShader } from 'three/examples/jsm/shaders/SobelOperatorShader';
import { BasicShadowMap } from 'three/src/constants';
import { CYCLE_TIMER } from '../../constants';
import { Engine } from '../index';

class PostProcessing extends EffectComposer {
  constructor(engine: Engine, scene: Scene, camera: Camera) {
    super(engine.renderer);

    const renderPass = new RenderPass(scene, camera);
    this.addPass(renderPass);

    const effectGrayScale = new ShaderPass(LuminosityShader);
    effectGrayScale.enabled = false;
    this.addPass(effectGrayScale);

    const effectSobel = new ShaderPass(SobelOperatorShader);
    effectSobel.uniforms['resolution'].value.x = engine.boundaries.width;
    effectSobel.uniforms['resolution'].value.y = engine.boundaries.height;
    effectSobel.enabled = false;
    this.addPass(effectSobel);

    const params = {
      shape: 3,
      radius: 8,
      rotateR: 0,
      rotateB: 1,
      rotateG: 1,
      scatter: 0,
      blending: 1,
      blendingMode: 2,
      greyscale: false,
      disable: false
    };
    const halftonePass = new HalftonePass( engine.boundaries.width / 8, engine.boundaries.height / 8, params );
    this.addPass( halftonePass );

    const config = [
      [false, false, false], // none
      [false, false, false], // none
      [false, true, true], // Dots abstract
      [false, true, false], // Sobel
      [false, true, false], // Sobel
    ];

    let angles = [...config];

    function randomConfig() {
      const configuration = sample(angles) as any;

      const index = angles.indexOf(configuration);
      if (index > -1) angles.splice(index, 1);

      if (!angles.length) angles = [...config];
      return configuration;
    }

    const setConfig = () => {
      const [gray, sobel, halftone] = randomConfig();
      effectGrayScale.enabled = gray;
      effectSobel.enabled = sobel;
      halftonePass.enabled = halftone;

      // const shadowMap = gray || sobel || halftone;
      // console.log(shadowMap);
      // engine.renderer.shadowMap.type = shadowMap ? PCFSoftShadowMap : BasicShadowMap;
      // engine.renderer.shadowMap.needsUpdate = true;
      // console.log(engine.renderer.shadowMap.type);
    };

    setConfig();

    setInterval(() => setConfig, CYCLE_TIMER);
    window.addEventListener('pointerdown', setConfig);


  }
}

export { PostProcessing };
