import {KTX2Loader} from 'three/examples/jsm/loaders/KTX2Loader';

/**
 * Detect whether KTX2/Basis is _actually_ supported.
 * THREE's support detection seems to be inconsistent at times.
 */
async function checkKtxSupport(loader: KTX2Loader, texturePath: string) {
  let isSupported = false;

  /**
   * ts-ignored until KTX2Loader.workerConfig type is implemented in typings.
   * @see [KTX2Loader Source]{@link https://bit.ly/2XiFPVB}
   * @see [Current KTX2Loader Types]{@link https://bit.ly/3hqlZyx}
   */
  // @ts-ignore
  const workerConfig = loader.workerConfig;
  const extensions = Object.values(workerConfig);

  // Check whether at least 1 of the KTX2 required extensions are supported.
  const supportsKTX2Extension = extensions.find(e => e === true);

  if (supportsKTX2Extension) {
    try {
      // Load a small basis texture to test.
      const texture = await loader.loadAsync(texturePath);
      // The texture is valid if the width greater than 0.
      isSupported = texture.image?.width > 0;
      // Release the texture from memory.
      texture.dispose();
    } catch (error) {
      // Basis is not supported.
      isSupported = false;
    }
  }

  return isSupported;
}

export {checkKtxSupport};
