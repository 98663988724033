import { ACESFilmicToneMapping, PCFSoftShadowMap, WebGLRenderer, WebGLRendererParameters } from 'three';
import { BasicShadowMap } from 'three/src/constants';
import { pcss } from './utilities/graphics/pcss/pcss';

export const DEFAULT_RENDERER_OPTIONS: WebGLRendererParameters = {
  antialias: false,
  alpha: false,
  preserveDrawingBuffer: false,
  powerPreference: 'high-performance'
};

export function createRenderer(options: WebGLRendererParameters = {}):
  WebGLRenderer {
  const renderer = new WebGLRenderer({ ...DEFAULT_RENDERER_OPTIONS, ...options });
  renderer.toneMapping = ACESFilmicToneMapping;
  renderer.toneMappingExposure = 1.3;
  renderer.sortObjects = false;
  // renderer.autoClear = false;
  // renderer.autoClearColor = false;
  // renderer.autoClearDepth = false;
  // renderer.autoClearStencil = false;
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = PCFSoftShadowMap;

  // // We don't have to recalculate shadows every frame.
  // renderer.shadowMap.autoUpdate = false;


  // @TODO(pdewit): turn off for production.
  renderer.debug.checkShaderErrors = false;
  renderer.physicallyCorrectLights = true;

  // pcss();

  return renderer;
}
