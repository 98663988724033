import {Loader, LoadingManager, Texture, TextureEncoding, TextureLoader, WebGLRenderer} from 'three';
import {DRACOLoader} from 'three/examples/jsm/loaders/DRACOLoader';
import {GLTF, GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import {KTX2Loader} from 'three/examples/jsm/loaders/KTX2Loader';

import {checkKtxSupport} from '../../common/capabilities/check-ktx-support';
import {parseFile} from '../../common/utilities/loaders/parse-file';
import {parseGltfFromArrayBuffer} from '../../common/utilities/loaders/parse-gltf-from-arraybuffer';

import {BASIS_PATH, Cache, DEBUG_LOG_TEXTURE_TRANSFER_TIME, DRACO_PATH, LoadManifest, PATH_TO_KTX2_TEXTURE, TextureManifestItem} from '../constants';
import {fileNameFromPath} from '../../common/utilities/string/file-name-from-path';
import {filenameWithoutExtension} from '../../common/utilities/string/filename-without-extension';

/**
 * @TODO(pdewit): add THREE.LoadingManager class to keep track of global
 *    loading progress.
 */

class Loaders {
  private readonly renderer: WebGLRenderer;
  public readonly manager: LoadingManager;
  public readonly gltf: GLTFLoader;
  public readonly texture: TextureLoader;
  public readonly draco: DRACOLoader;
  public readonly ktx2: KTX2Loader;

  public readonly textureCache: Cache<Texture> = {};
  public readonly modelCache: Cache<GLTF> = {};
  public ktx2Supported = false;

  /** @TODO(pdewit): Find a way to remove the renderer as a dependency. */
  constructor(renderer: WebGLRenderer) {
    this.renderer = renderer;
    this.manager = new LoadingManager();
    this.gltf = new GLTFLoader(this.manager);
    this.texture = new TextureLoader(this.manager);
    this.draco = new DRACOLoader(this.manager);
    this.ktx2 = new KTX2Loader(this.manager);


    this.manager.onProgress = this.onManagerProgress;
    this.manager.onLoad = this.onManagerLoad;
  }

  public onManagerLoad = () => {
    console.log('done');
  };

  public onManagerProgress = (url: string, loaded: number, total: number) => {
    const progress = loaded / total;
    // console.log(url, total);
  };

  /**
   * Some loaders need some preparation before they can be used.
   * Both KTX2 and DRACO loaders need a reference to a static file containing
   *    transcoders.
   */
  public async setup(): Promise<void> {
    // Set the location of the DRACO worker transcoder.
    this.draco.setDecoderPath(DRACO_PATH);
    this.gltf.setDRACOLoader(this.draco);

    // Set the location of the BASIS transcoder.
    this.ktx2.setTranscoderPath(BASIS_PATH);
    /**
     * Detect whether our WebGL context has all required `extensions`.
     * @see [WebGLRenderingContext.getExtension()]{@link https://mzl.la/3hqiCrn}
     */
    this.ktx2.detectSupport(this.renderer);
    // Check if KTX2 is _actually_ supported.
    this.ktx2Supported = await checkKtxSupport(this.ktx2, PATH_TO_KTX2_TEXTURE);
  }

  /** Load, transfer and cache a texture. */
  public async loadTexture(
      path: string,
      loader: Loader = this.texture,
      encoding?: TextureEncoding
  ): Promise<Texture> {
    if (!this.textureCache[path]) {
      const texture = await loader.loadAsync(path);

      // Give the texture a name. @todo(pdewit): check if we need this
      const fileName = fileNameFromPath(path);
      if (fileName) texture.name = filenameWithoutExtension(fileName);

      if (encoding) {
        texture.encoding = encoding;
        texture.needsUpdate = true;
      }
      this.textureCache[path] = texture;

      if (DEBUG_LOG_TEXTURE_TRANSFER_TIME) console.time(path);
      /** @see {WebGLRenderer.initTexture} */
      this.renderer.initTexture(this.textureCache[path]);
      if (DEBUG_LOG_TEXTURE_TRANSFER_TIME) console.timeEnd(path);
    }
    return this.textureCache[path];
  }

  /**
   * Load a KTX/BASIS texture if its supported. Otherwise load a reliable
   *    fallback image.
   */
  public async loadTextureFromManifest(item: TextureManifestItem) {
    let loader: Loader = this.texture;
    let pathToLoad = item.image;

    if (this.ktx2Supported && item.ktx2) {
      loader = this.ktx2;
      pathToLoad = item.ktx2;
    }

    return await this.loadTexture(pathToLoad, loader, item.encoding);
  }

  /** Load and cache a GLTF. */
  public async loadModel(path: string): Promise<GLTF> {
    if (!this.modelCache[path]) {
      this.modelCache[path] = await this.gltf.loadAsync(path);
    }
    return this.modelCache[path];
  }

  /** Parse a model from a file upload. */
  public async parseModelFromFile(file: File): Promise<GLTF | undefined> {
    const contents = await parseFile(file);
    if (contents) return await parseGltfFromArrayBuffer(this.gltf, contents);
  }

  /** Get a model synchronously if you are certain it has been loaded before. */
  public getModel(path: string): GLTF | undefined {
    return this.modelCache?.[path];
  }

  /** Batch-load a list of models and textures. */
  public async loadManifest(manifest: LoadManifest) {
    const promises: Promise<any>[] = [];

    if (manifest.models) {
      for (const model of manifest.models) {
        promises.push(this.loadModel(model.path));
      }
    }

    if (manifest.textures) {
      for (const texture of manifest.textures) {
        promises.push(this.loadTextureFromManifest(texture));
      }
    }

    return await Promise.all(promises);
  }

  /** Dispose all cached assets and release memory. */
  public dispose() {
    // Dispose and remove references to textures.
    for (const key in this.textureCache) {
      if (this.textureCache.hasOwnProperty(key)) {
        this.textureCache[key].dispose();
        delete this.textureCache[key];
      }
    }

    // Dispose and remove references to models.
    for (const key in this.modelCache) {
      if (this.modelCache.hasOwnProperty(key)) {
        delete this.modelCache[key];
      }
    }
    this.draco.dispose();
    this.ktx2.dispose();
  }
}

export {Loaders};
