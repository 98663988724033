import { defineQuery, defineSystem, } from 'bitecs';
import { Placeable } from '../components/placeable';
import { Velocity } from '../components/velocity';

function createMovementSystem() {
  const movementQuery = defineQuery([Placeable, Velocity]);

  return defineSystem((world) => {
    const entities = movementQuery(world);

    for (let i = 0; i < entities.length; ++i) {
      const id = entities[i];

      Placeable.x[id] += Velocity.x[id];
      Placeable.y[id] += Velocity.y[id];
      Placeable.z[id] += Velocity.z[id];
    }

    return world;
  });
}

export { createMovementSystem };
