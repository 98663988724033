import './index.css';
import { Game } from './lib';
import { Boundaries } from './lib/common/utilities/math/boundaries';
import { Engine } from './lib/engine';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const canvas = document.querySelector('#game-ecs') as HTMLCanvasElement;
const boundaries = new Boundaries(window.innerWidth, window.innerHeight, window.devicePixelRatio);
const engine = new Engine(document.body, canvas, boundaries);


async function initialise(engine: Engine) {

  await engine.setup();

  const game = new Game(engine);
  game.start();

  const resize = () => game.resize(window.innerWidth, window.innerHeight, window.devicePixelRatio);

  window.addEventListener('resize', resize);
  resize();

}

initialise(engine);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
