import { defineComponent, Types } from 'bitecs';

/** The entity's camera view. */
const Camera = defineComponent({
  offsetX: Types.f32,
  offsetY: Types.f32,
  offsetZ: Types.f32,
  // fov: Types.f32,
  // rotateX: Types.f32,
  // rotateY: Types.f32,
  // rotateZ: Types.f32,
});

export { Camera };
